
import { debounce } from 'lodash';
import {computed, defineComponent} from 'vue';
import { useRoute } from 'vue-router';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {swalNotification} from '@/core/helpers/utils';
import {IVatTuResponseBase} from '@/core/interfaces/ApiResponses';
import {VatTuService} from '@/core/services/VatTu.service';
import {usePagination} from '@/core/hooks/use-pagination';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';

import DanhSachVatTuTable from '@/views/crafted/pages/vat-tu/danh-sach-vat-tu-table/DanhSachVatTuTable.vue';
import Pagination from '@/components/shared/pagination/Pagination.vue';
import PerPageSelection from '@/components/shared/per-page-selection/PerPageSelection.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';

export default defineComponent({
	name: 'danh-sach-vat-tu',

	components: {
		ButtonCustom,
		PerPageSelection,
		Pagination,
		PageRowLayout,
		DanhSachVatTuTable,
	},

	setup() {
		const { name: routeName } = useRoute();
		const { push } = useRouterCustom();
		const { ButtonsType, ButtonTypeColors } = useButtonCustom();
		const { currentPage, totalItems, perPage, perPageArr, loading, searchTerm } = usePagination();

		const isInaccessible = computed(() => {
			if (routeName === 'update-supply') return process.env.VUE_APP_IS_EDIT_VAT_TU_ENABLE === 'false';

			return process.env.VUE_APP_IS_ADD_VAT_TU_ENABLE === 'false';
		});

		return {
			push,
			searchTerm,
			currentPage,
			isInaccessible,
			perPage,
			totalItems,
			perPageArr,
			loading,
			ButtonsType, ButtonTypeColors,
		}
	},

	data() {
		return {
			items: [] as IVatTuResponseBase[]
		}
	},

	async mounted() {
		setCurrentPageTitle("Vật tư");

		await this.fetchVatTuItems();
	},

	watch: {
		async currentPage() {
			await this.fetchVatTuItems();
		},

		async perPage() {
			await this.fetchVatTuItems();
		}
	},

	methods: {
		async fetchVatTuItems() {
			try {
				this.loading = true;
				const { data: { data: { data, total } } } = await VatTuService.list({
					per_page: this.perPage,
					current_page: this.currentPage,
					s: this.searchTerm,
				});

				this.items = data;

				this.totalItems = total;

				this.loading = false;
			} catch {
				await swalNotification('Có lỗi xảy ra khi lấy thông tin danh sách vật tư', 'error');
				this.loading = false;
			}
		},

		searchForVatTu: debounce(function() {
      this.fetchVatTuItems();
    }, 500),
	}
})
