
import {defineComponent, PropType} from 'vue';

export default defineComponent({
	name: 'per-page-selection',

	emits: ['change'],

	props: {
		perPage: {
			type: Number,
			required: true,
		},
		perPageArr: {
			type: Array as PropType<Array<number>>,
			required: true,
		},
	},

	data() {
		return {
			value: this.perPage,
		}
	},

	watch: {
		perPage(value) {
			this.value = value;
		},

		value() {
			this.$emit('change', this.value);
		}
	}
})
