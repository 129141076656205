
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'pagination',

	emits: ['change-page'],

	props: {
		total: {
			type: Number,
			required: true,
		},
		page: {
			type: Number,
			required: true,
		},
		pageSize: {
			type: Number,
			required: true,
		},
		pagerCount: {
			type: Number,
			default: 5,
		}
	},

	data() {
		return {
			currentPage: this.page,
		}
	},

	watch: {
		page(value) {
			this.currentPage = value;
		}
	},

	methods: {
		change(value) {
			this.currentPage = value;
			this.$emit('change-page', this.currentPage);
		}
	}
})
